// @ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Switch } from 'zzc-design-mobile';
import './styles.scss';

SwitchButton.propTypes = {
  open: PropTypes.bool,
  onSwitch: PropTypes.func
};

SwitchButton.defaultProps = {
  open: false,
  onSwitch: () => {}
};

export function ZZCSwitchButton({open, onSwitch}) {
  return (
    <Switch checked={open} onChange={(value) => { onSwitch(value) }} />
  );
}
interface SwitchButtonProps {
  open: boolean;
  onSwitch: (value: any) => void;
  gray?: boolean;
}

export default function SwitchButton({open, onSwitch, gray}: SwitchButtonProps) {
  const btnClass = classnames({
    'switch-btn': true,
    'switch-btn-checked': open,
    'switch-btn-gray': gray
  });
  return (
    <div className="switch-btn-container">
      <div className={btnClass} onClick={onSwitch}>
      </div>
    </div>
  );
}
